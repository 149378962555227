// @ts-check

import { PaperClipOutlined } from '@ant-design/icons';
import ConfirmModal from "components/Shared/ConfirmModal";
import { FileUploadContextManager, FileUploadDragger, FileUploadError, FileUploadIconButton, FileUploadList, FileUploadNoContext } from "components/Shared/FileUpload";
import RoundIconButton from "components/Shared/RoundIconButton";
import { useZeroContext } from "components/ZeroContext";
import { useCurrentUser } from "hooks/reduxHooks";
import { isOfflineRoute } from "offline/utils";
import { generateUUID, isPublicUser } from "other/Helper";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";

/**
 * @param {{
 *  initialAttachments?: any[],
 *  submissionId?: string,
 *  formId: string,
 *  fieldId: string,
 *  attachmentUploadButtonRef: any,
 *  showDragger?: boolean,
 *  onUpload: (attachments: any[]) => void,
 *  onPreview: (attachments: any[], index: number) => void,
 * }} props
 */
export default function FieldAttachments({
    initialAttachments,
    submissionId: propsSubmissionId,
    formId,
    fieldId,
    attachmentUploadButtonRef,
    showDragger=true,
    onUpload,
    onPreview,
}) {
    const context = useZeroContext();
    const user = useCurrentUser();
    const [attachments, setAttachments] = useState(initialAttachments ?? []);
    const [attachmentIndexToDelete, setAttachmentIndexToDelete] = useState(null);
    const {submission_uuid: paramsSubmissionId} = useParams();
    
    const showDeleteConfirmation = attachmentIndexToDelete !== null;
    const submissionId = propsSubmissionId || paramsSubmissionId;

    const onDelete = (index) => setAttachmentIndexToDelete(index);

    const handleDeleteConfirmation = () => {
        const newAttachments = [...attachments];
        const deletedAttachments = newAttachments.splice(attachmentIndexToDelete, 1);
        if (isOfflineRoute() && deletedAttachments.length > 0) {
            context.services.forms.deleteLocalSubmissionAttachment(submissionId, deletedAttachments[0].attachment_uuid);
        }
        setAttachments(newAttachments);
        setAttachmentIndexToDelete(null);
    };

    const generateParamsCallback = (file) => ({
        feature_key: "forms_submission",
        form_uuid: formId,
        field_uuid: fieldId,
        content_type: file.type,
        file_name: file.name,
        embedded: false,
    });

    const onUploadSuccess = (file, preSignedUrl, fileKey) => {
        const newAttachment = {
            file_path: fileKey,
            file_name: file.name,
            public_url: preSignedUrl + "/" + fileKey,
            mime_type: file.type,
        };

        setAttachments((attachments) => [...attachments, newAttachment]);
    };

    const onUploadError = (error) => {
        console.error(error);
    };

    const customUploadHandler = useMemo(() => {
        if (isPublicUser(user)) {
            return (file) => {
                const attachmentId = generateUUID();
                const url = URL.createObjectURL(file);
                const attachment = {
                    attachment_uuid: attachmentId,
                    file_path: url,
                    file_name: file.name,
                    public_url: url,
                    mime_type: file.type,
                    file: file,
                };

                setAttachments((attachments) => [...attachments, attachment]);
            };
        } else if (
            submissionId &&
            context.caches.submissionDrafts.drafts.filter((draft) => draft._id === submissionId).length > 0
        ) {
            return async (file) => {
                const attachment = await context.services.forms.drafts.saveOfflineAttachment(submissionId, file);
                setAttachments((attachments) => [...attachments, attachment]);
            };
        }

        return null;
    }, [user, submissionId, context]);

    useEffect(() => {
        onUpload(attachments);
    }, [attachments]);

    return (
        <FileUploadContextManager
            attachments={attachments}
            generateParamsCallback={generateParamsCallback}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            customUpload={customUploadHandler}
        >
            {showDeleteConfirmation && (
                <ConfirmModal
                    show={showDeleteConfirmation}
                    cancel={() => setAttachmentIndexToDelete(null)}
                    confirm={handleDeleteConfirmation}
                    title={"Confirmation"}
                    body={"Are you sure you want to delete this attachment?"}
                    confirmButtonName={"Delete"}
                />
            )}
            { attachmentUploadButtonRef.current &&
                createPortal(
                    <FileUploadIconButton hideWhenDragging={showDragger} customButton={<RoundIconButton icon={PaperClipOutlined} />} />,
                    attachmentUploadButtonRef.current
                )
            } 
            { showDragger && <FileUploadDragger /> }
            <FileUploadError />
            <FileUploadList className="mar-btm-0" onDelete={onDelete} onPreview={(index) => onPreview(attachments, index)} />
        </FileUploadContextManager>
    );
}
